
/* ######## Start Loader Css########## */

:root {
    --grey: #0F1730;
    /* --spot: #68ebbc; */
    --animation-speed: 2s;
  }
  
  .mainLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    position: fixed;
    z-index: 99999;
    /* background:rgba(255, 253, 253, 0.822); */
    background: rgba(255, 255, 255, 0.4);
  
  
  }
  
  
  
  .img_size {
    width: 60px;
  }
  
  .preloader {
    display: block;
    position: relative;
    width: 16px;
    height: 6px;
    margin: 10px auto;
    background: var(--grey);
    animation-name: loader;
    animation-duration: var(--animation-speed);
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
  }
  
  .preloader::before,
  .preloader::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: var(--grey);
    animation-duration: var(--animation-speed);
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
  }
  
  .preloader::before {
    left: 0;
    translate: -100% -50%;
    border-right-color: transparent;
    animation-name: loader-left;
  }
  
  .preloader::after {
    right: 0;
    translate: 100% -50%;
    border-left-color: transparent;
    animation-name: loader-right;
  }
  
  @keyframes loader {
  
    0%,
    10% {
      scale: 1;
    }
  
    15% {
      scale: 0.7 1.2;
    }
  
    40%,
    60% {
      scale: 1;
    }
  
    65% {
      scale: 0.7 1.2;
    }
  
    100% {
      scale: 1;
    }
  }
  
  @keyframes loader-left {
    0% {
      translate: -400% -50%;
      scale: 1.3 0.6;
    }
  
    10%,
    60% {
      translate: -100% -50%;
      scale: 1;
    }
  
    65%,
    85% {
      translate: -300% -50%;
    }
  
    87.5% {
      translate: -310% -50%;
    }
  
    90% {
      translate: -290% -50%;
    }
  
    92.5% {
      translate: -310% -50%;
      scale: 1;
    }
  
    95% {
      translate: -290% -50%;
    }
  
    100% {
      translate: -400% -50%;
    }
  }
  
  @keyframes loader-right {
  
    0%,
    10% {
      translate: 100% -50%;
      scale: 1;
    }
  
    15%,
    35% {
      translate: 300% -50%;
    }
  
    15% {
      scale: 1.3 0.6;
    }
  
    20% {
      scale: 1;
    }
  
    37.5% {
      translate: 310% -50%;
    }
  
    40% {
      translate: 290% -50%;
    }
  
    42.5% {
      translate: 310% -50%;
    }
  
    45% {
      translate: 290% -50%;
      scale: 1;
    }
  
    50% {
      translate: 400% -50%;
      scale: 1.3 0.6;
    }
  
    60%,
    100% {
      translate: 100% -50%;
      scale: 1;
    }
  }
  
  
  @media only screen and (min-width: 900px) {
    .mainLoader {
      padding: 35px;
    }
  }
  
  /* ########## End Loader Css ################# */