  /* ########## Pegination CSS ############# */

  .pagination {
    display: -ms-flexbox !important;
    display: flex !important;
    padding-left: 0 !important;
    list-style: none !important;
    border-radius: .25rem !important
  }
  
  .page-link {
    position: relative !important;
    display: block !important;
    padding: 4px 8px   !important;
    margin-left: -1px !important;
    line-height: 1.25 !important;
    color: #0f1730 !important;
    background-color: #fff !important;
    border: 1px solid #dee2e6 !important
  }

  @media (max-width: 600px) {
    .page-link {
     
      padding: 4px 2px!important;
     
    }
}
  
  .page-link:hover {
    z-index: 2 !important;
    color: #0f1730 !important;
    text-decoration: none !important;
    background-color: #e9ecef !important;
    border-color: #dee2e6 !important
  }
  
  .page-link:focus {
    z-index: 3 !important;
    outline: 0 !important;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25) !important
  }
  
  .page-item:first-child .page-link {
    /* margin-left: 0 !important; */
    /* border-top-left-radius: .25rem !important; */
    /* border-bottom-left-radius: .25rem !important */
  }
  
  .page-item:last-child .page-link {
    /* border-top-right-radius: .25rem !important; */
    /* border-bottom-right-radius: .25rem !important */
  }
  
  .page-item.active2 .page-link {
    /* z-index: 3 !important; */
    color: #fff !important;
    background-color: #0f1730 !important;
    border-color: #0f1730 !important
  }
  
  .page-item.disabled .page-link {
    color: #6c757d !important;
    pointer-events: none !important;
    cursor: auto !important;
    background-color: #fff !important;
    border-color: #dee2e6 !important
  }
  
  .pagination-lg .page-link {
    /* padding: .75rem 1.5rem !important; */
    font-size: 1.25rem !important;
    line-height: 1.5 !important
  }
  
  .pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: .3rem !important;
    border-bottom-left-radius: .3rem !important
  }
  
  .pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: .3rem !important;
    border-bottom-right-radius: .3rem !important
  }
  
  .pagination-sm .page-link {
    /* padding: .25rem .5rem !important; */
    font-size: .875rem !important;
    line-height: 1.5 !important
  }
  
  .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .2rem !important;
    border-bottom-left-radius: .2rem !important
  }
  
  .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .2rem !important;
    border-bottom-right-radius: .2rem !important
  }
  
  /* ########## Pegination CSS ############# */