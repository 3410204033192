/* Reset CSS */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}



.align {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: ; */
    /* height: 100vh; */
}

.login_text {
    color: #168DE4;
    color: #173fae;
    font-weight: 700; 
}
.heading_text_login{
    color: #ffffff !important;
    font-weight: 600;
    font-family: Georgia, 'Times New Roman', Times, serif;
}
.heading_text {
    color: #272a2c !important;
    font-weight: 600;
    font-family: Georgia, 'Times New Roman', Times, serif;
    /* font-family: "Kanit", sans-serif;
    font-weight: 500; */
}

.heading_bg {
    /* background-color: rgb(255, 255, 255); */
}



.align_form {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);

}

.main_content {
    border: 1px solid rgba(0, 0, 0, 0.39);
    border-radius: 20px;
}


.text_color {
    color: #db4174 !important;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
}

.poppins {
    /* font-family: "Poppins", sans-serif !important; */

}
.form-select{
    border: var(--bs-border-width) solid #babcbf;
    height: 35px !important;
}
.form-control{
    border: var(--bs-border-width) solid #babcbf;
}

.container_bg {
    /* background: url(../../../public/assets/img/Login/logi_bg-01.jpg); */
    /* background: url(../../../public/assets/img/Login//login_bg_white_new-01.jpg); */
    background-color: #36454F !important;
    background-size: 100% 100%;
    overflow-x: hidden !important;

}

.logo_size {
    width: 80px;
    background-color: white;
    border-radius: 100%;
    padding: 5px;
}

.logn_button {
    border: 1px solid gray;
    color: rgb(5, 5, 5);
    font-weight: 600 !important;
    /* background-color: #83C32F; */

}

.bg-submit {
    background-color: #f31a53;
}

.reg_link {
    text-decoration: underline;
    font-weight: 600;
    color: #f31a53;
}
.bg_btns_login{
  background-color: #14388017;
}
.bg_btns_login:hover{
  /* background-color: rgba(20, 62, 134, 0.336);
  color: white !important; */
  text-decoration: none;
  color: #f31a53;
}
.forgot_link {
    color: rgb(20 62 134);
    text-align: end;
    justify-content: flex-end;
    display: flex;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
}

.logn_button:hover {
    border: 1px solid gray;
    background-color: #f31a54c7;
    color: white;
    font-size: 14px;
}

.footer_text {
    color: #272a2c;
    font-size: 14px;
}

.jayate_logo_size {
    width: 50px !important;
}

.login_logo_size {
    width: 280px !important;
}

.slogan {
    color: #3e3e3e;
    font-size: 16px;
    font-weight: bold;
    font-family: Georgia, 'Times New Roman', Times, serif
}

.slogan_app {
    color: #3e3e3e;
    font-size: 22px;
    font-weight: bold;
    font-family: Georgia, 'Times New Roman', Times, serif
}

.bg-Namepara {
    background-color: #F7F0F1;
}

.satyamev {
    text-align: center;
}

@media screen and (max-width: 800px) {
    .logo_size {
        width: 60px;
        background-color: white;
        border-radius: 100%;
        padding: 5px;
    }

    .login_logo_size {
        width: 150px !important;
    }

    .heading_text {
        font-size: 15px !important;
    }

    .display {
        display: none !important;
    }

    .slogan_app {
        font-size: 18px;
    }

    /* .login_logo_size {
        width: 100px !important;
    } */

    .text_fontsize{
        font-size: 12px !important;
        text-align: center !important;
    }


    .satyamev {
        text-align: start;
    }

    .jayate_logo_size {
        width: 40px !important;
    }
}

/* #pass{
    position: relative;
}

#eye-span{
    position: absolute;
    right: 3px !important;
} */

.validation-error {
    border: 1px solid red !important
}

.note_text{
   font-size: 12px !important;
   color: red;
   
}
















/*//////////////////////////////////////////////////////////////////
  [ New login ]*/

  .limiter {
    width: 100%;
    margin: 0 auto;
  }
  
  .container-login100 {
    width: 100%;
    /* min-height: 100vh; */
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    /* background: #9053c7; */
    /* background: -webkit-linear-gradient(-135deg, #c850c0, #4158d0); */
    /* background: -o-linear-gradient(-135deg, #c850c0, #4158d0); */
    /* background: -moz-linear-gradient(-135deg, #c850c0, #4158d0); */
    /* background: linear-gradient(-135deg, #c850c0, #4158d0); */
  }
  
  .wrap-login100 {
    width: 100%;
    max-width: 1150px;
    background: #f6f0f0;
    border-radius: 20px;
    overflow: hidden;
    padding: 28px 32px 15px;
    margin: 0 auto;
    min-height: calc(100vh - 30px);
  }
  
  .wrap-login100 .header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
  }
  
  .wrap-login100 .header {
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 6px 44px;
    background: #fff;
    border: 1px solid #686465;
    border-radius: 15px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .2);
    margin-bottom: 26px;
  }
  
  .wrap-login100 .header img {
    max-height: 100px;
    width: auto;
  }
  
  .wrap-login100 .header .loginmiddal {
    font-size: 19px;
    font-weight: 600;
    text-align: center;
  }
  
  .wrap-login100 .header .loginmiddal span {
    display: block;
    color: #173fae;
    font-size: 20px;
  }
  
  .login-hero {
    background: #cbf3fd;
    border: 1px solid #49646b;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .2);
    margin-bottom: 26px;
    overflow: hidden;
  }
  
  .login-hero img {
    width: 100%;
    max-width: 100%;
  }
  
  .login-box {
    background: white;
    margin: 0;
    padding: 32px 20px;
    border: 1px solid black;
    border-radius: 15px;
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, .2);
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    height: 100%;
  }
  
  .login-box .content {
    /* max-width: 328px; */
    margin: 0 auto;
    width: 100%;
  }
  
  .login-body .col-12 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  
  /*------------------------------------------------------------------
    [  ]*/
  .login100-pic {
    /* width: 316px; */
  }
  
  .login100-pic img {
    max-width: 100%;
  }
  
  
  /*------------------------------------------------------------------
    [  ]*/
  .login100-form {
    /* width: 290px; */
  }
  
  .login100-form-title {
    font-size: 1.5rem;
    color: #000000;
    line-height: 1.2;
    text-align: center;
    font-weight: 600;
    width: 100%;
    display: block;
    padding-bottom: 20px;
  }
  
  
  /*---------------------------------------------*/
  .wrap-input100 {
    position: relative;
    width: 100%;
    z-index: 1;
    margin-bottom: 18px;
  }
  
  .input100 {
    font-size: 15px;
    line-height: 1.5;
    color: #000;
    display: block;
    width: 100%;
    background: #fff;
    height: 50px;
    border-radius: 10px;
    padding: 0 55px;
    border: 1px solid #9b9d9c;
  }
  
  
  /*------------------------------------------------------------------
    [ Focus ]*/
  .focus-input100 {
    display: block;
    position: absolute;
    border-radius: 25px;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 0px 0px;
    color: rgba(214, 14, 26, 0.8);
  }
  
  .input100:focus+.focus-input100 {
    -webkit-animation: anim-shadow 0.5s ease-in-out forwards !important;
    animation: anim-shadow 0.5s ease-in-out forwards !important;
  }
  
  @-webkit-keyframes anim-shadow {
    to {
      box-shadow: 0px 0px 70px 25px;
      opacity: 0;
    }
  }
  
  @keyframes anim-shadow {
    to {
      box-shadow: 0px 0px 70px 25px;
      opacity: 0;
    }
  }
  
  .symbol-input100 {
    font-size: 15px;
  
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    position: absolute;
    border-radius: 25px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-left: 35px;
    pointer-events: none;
    color: #666666;
  
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  }
  
  .input100:focus+.focus-input100+.symbol-input100 {
    color: #ee1451 !important;
    padding-left: 28px;
  }
  
  /*------------------------------------------------------------------
    [ Button ]*/
  /* .container-login100-form-btn {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 5px;
  } */
  
  /* .login100-form-btn {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.3;
    color: #fff;
    text-transform: capitalize;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    background: #ee1451;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 15px;
  
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  } */
  
  .login100-form-btnDisable {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.3;
    color: #fff;
    text-transform: capitalize;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    background: gray;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 15px;
  
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
    cursor: not-allowed;
  }
  
  .login100-form-btn:hover {
    background: #333333;
  }
  
  .login-box .btn.btn-primary {
    background-color: #ee1451;
    border-color: #ee1451;
    line-height: 1.7;
    font-size: 1.15rem;
    border-radius: 10px;
    white-space: wrap;
  }
  
  .login-box .btn.btn-primary:hover {
    background-color: #0c1aff;
    border-color: #0c1aff;
  }
  
  .login-box .links {
    padding-top: 18px;
    text-align: center;
    margin-bottom: -15px;
  }
  
  .login-box .links a {
    font-weight: 600;
    font-size: 1.15rem;
    color: #0c1aff;
  }
  
  
  
  /*------------------------------------------------------------------
    [ Responsive ]*/
  
  
  
  @media (max-width: 992px) {
  
    html,
    body {
      font-size: 15px;
    }
  
    .wrap-login100 {
      padding: 20px 20px;
    }
  
    .login100-pic {
      width: 35%;
    }
  
    .login100-form {
      width: 100%;
    }
  
    .wrap-login100 .header .loginmiddal {
      font-size: 1.2rem;
    }
  
    .wrap-login100 .header .loginmiddal span {
      font-size: 1rem;
    }
  
    .wrap-login100 .header img {
      max-height: 80px;
    }
  
    .wrap-login100 .header {
      padding: 6px 20px;
    }
  }
  
  @media (max-width: 768px) {
  
    html,
    body {
      font-size: 14px;
    }
  
    .wrap-login100 {
      padding: 15px;
    }
  
    .login100-pic {
      display: none;
    }
  
    .login100-form {
      width: 100%;
    }
  
    .login-box .btn.btn-primary {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 576px) {
  
    html,
    body {
      font-size: 13px;
    }
  
    .wrap-login100 {
      padding: 15px;
    }
  
    .wrap-login100 .header img {
      max-height: 60px;
    }
  
    .wrap-login100 .header .loginmiddal {
      font-size: 1rem;
    }
  
    .wrap-login100 .header .loginmiddal span {
      font-size: .8rem;
    }
  }
  
  @media (max-width: 460px) {
  
    html,
    body {
      font-size: 12px;
    }
  
    .wrap-login100 {
      padding: 15px;
    }
  
    .wrap-login100 .header img {
      max-height: 50px;
    }
  
    .wrap-login100 .header .loginmiddal {
      font-size: .9rem;
    }
  
    .wrap-login100 .header .loginmiddal span {
      font-size: .7rem;
    }
  }
  
  @media (max-width: 410px) {
    .wrap-login100 .header {
      gap: 10px;
      justify-content: center;
    }
  
    .loginLogo {
      order: 1;
    }
  
    .loginmiddal {
      order: 3;
      width: 100%;
      margin-top: -5px;
    }
  
    .rightLogo {
      order: 2;
    }
  }
  
  
  /*------------------------------------------------------------------
    [ Alert validate ]*/
  
  .validate-input {
    position: relative;
  }
  
  .alert-validate::before {
    content: attr(data-validate);
    position: absolute;
    max-width: 70%;
    background-color: white;
    border: 1px solid #c80000;
    border-radius: 13px;
    padding: 4px 25px 4px 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 8px;
    pointer-events: none;
  
    font-family: Poppins-Medium;
    color: #c80000;
    font-size: 13px;
    line-height: 1.4;
    text-align: left;
  
    visibility: hidden;
    opacity: 0;
  
    -webkit-transition: opacity 0.4s;
    -o-transition: opacity 0.4s;
    -moz-transition: opacity 0.4s;
    transition: opacity 0.4s;
  }
  
  .alert-validate::after {
    content: "\f06a";
    font-family: FontAwesome;
    display: block;
    position: absolute;
    color: #c80000;
    font-size: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 13px;
  }
  
  .alert-validate:hover:before {
    visibility: visible;
    opacity: 1;
  }
  
  .card-heading {
    padding: 15px;
    color: #3c5cff;
    font-weight: 700;
    background: #e3e8ff;
  }
  
  @media (max-width: 992px) {
    .alert-validate::before {
      visibility: visible;
      opacity: 1;
    }
  }
  
  .error {
    color: red;
  }

  .tel_fontsize{
    font-size: 18px !important;
  }
  
  .text-primary-color{
    color: #123bb3 !important;
  }